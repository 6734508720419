:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  /* color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  /* color: #646cff; */
  text-decoration: inherit;
}
a:hover {
  /* color: #535bf2; */
  opacity: 0.7;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  height: 100%;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
/* Pagination Buttons */
.pagination.bp span button {
  background-color: #f5f6fb;
  border: 1px solid #3e5988;
  color: #3e5988;
}

.disabled {
  color: #dbe1e9 !important;
  background-color: var(--blue-white);
  border: 1px solid #dbe1e9;
}

.pagination.bp span button:disabled {
  color: #dbe1e9 !important;
  background-color: var(--blue-white);
  border: 1px solid #dbe1e9;
}

button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}
